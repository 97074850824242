import React, { useState } from "react";
import Footer from "./Footer";
import DownloadBtn from "./DownloadBtn";
import LanguageSelector from './LanguageSelector';
import "./home_web.scss";
import { useTranslation } from 'react-i18next';

const isNsfw = window.location.hostname.includes('nsfw');

const BannerItem: React.FC<{ title: string, desc: string, img: string, index: number }> = ({ title, desc, img, index }) => {
  const imgLeft = index % 2 === 0;
  return (
    <div className="banner-item flex items-center text-start mt-100 mb-100">
      {imgLeft &&
        <div className="m-10 pr-[2vw]">
          <div><img src={img} className="w-banner-img" /></div>
        </div>}
      <div className={imgLeft ? "flex-1 pl-[2vw]" : "flex-1 pr-[2vw]"}>
        <div className="text-44 text-white font-bold mt-20 mb-10" style={{ maxWidth: '50vw' }} dangerouslySetInnerHTML={{ __html: title }}></div>
        <div className="text-22 text-white font-[15] mt-20" style={{ maxWidth: '50vw' }} dangerouslySetInnerHTML={{ __html: desc }}></div>
        <div className="mt-30">
          <TryItBtn long={false} />
        </div>
      </div>
      {!imgLeft &&
        <div className="m-10 pl-[2vw]">
          <div><img src={img} className="w-banner-img" /></div>
        </div>}
    </div>
  );
};

const FAQItem: React.FC<{ question: string, answer: string }> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-faq-item">
      <div className="flex items-center cursor-pointer" onClick={toggleAnswer}>
        <span className="w-faq-question">{question}</span>
        <span className={`transition-transform duration-300 text-gray ${isOpen ? 'transform rotate-180' : ''}`}>
          ▼
        </span>
      </div>
      {isOpen && <div className="w-faq-answer mt-26" dangerouslySetInnerHTML={{ __html: answer }}></div>}
    </div>
  );
};

const TryItBtn: React.FC<{ long: boolean }> = ({ long }) => {
  const { t } = useTranslation();
  const buttonStyle = {
    '--button-text': `"${t('common.try_btn')}"`,
    'width': long ? '350px' : '198px',
    'height': long ? '40px' : '36px',
  } as React.CSSProperties;
  return (
    <a href="https://p.aipeak.ai" className="w-try-button py-2 px-4 " style={buttonStyle}>
    </a>
  );
}

function Home() {
  const { t } = useTranslation();
  const banners = [
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_01.webp',
      title: t('banner.title_1'), desc: t('banner.desc_1'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_02.webp',
      title: t('banner.title_2'), desc: t('banner.desc_2'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_03.webp',
      title: t('banner.title_3'), desc: t('banner.desc_3'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_04.webp',
      title: t('banner.title_4'), desc: t('banner.desc_4'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_05.webp',
      title: t('banner.title_5'), desc: t('banner.desc_5'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_06.webp',
      title: t('banner.title_6'), desc: t('banner.desc_6'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_07.webp',
      title: t('banner.title_7'), desc: t('banner.desc_7'),
    },
  ];
  const faqs = [
    { question: t('faq.q1'), answer: t('faq.a1') },
    { question: t('faq.q2'), answer: t('faq.a2') },
    { question: t('faq.q3'), answer: t('faq.a3') },
    { question: t('faq.q4'), answer: t('faq.a4') },
    { question: t('faq.q5'), answer: t('faq.a5') },
    { question: t('faq.q6'), answer: t('faq.a6') },
    { question: t('faq.q7'), answer: t('faq.a7') },
  ];
  return (
    <>
      <main className="w-full min-h-screen mx-auto w-home-bg">
        <div className="header-container pt-60 pb-120">
          <img src={require('../../assets/images/home/ic_logo_white.webp')} className="home-logo" />
          <div className="purchase-button-container">
            <LanguageSelector h5={false} />
          </div>
        </div>
        <div className="flex items-center mt-15 module-container">
          <div className="flex-1 pr-[2vw]">
            <div className="text-74 text-white font-[800]">PicX</div>
            <div className="text-40 text-white" style={{ maxWidth: '50vw' }}>{t('common.home_subtitle')}</div>
            <div className="text-20 leading-37 pt-30 tracking-widest text-white font-[15]" style={{ maxWidth: '50vw' }} dangerouslySetInnerHTML={{ __html: t('common.home_desc') }}></div>
            <div className="mt-30 mb-10 flex flex-col items-start">
              <div className="flex space-x-4" style={{ maxWidth: '50vw' }}>
                {/* <div className="mr-30"><DownloadBtn type="ios" /></div> */}
                <DownloadBtn type="android" h5={false} />
                {isNsfw && (
                  <DownloadBtn type="webapp" h5={false} />
                )}
              </div>
            </div>
            <div className="text-20 leading-37 pt-30 tracking-widest text-white font-[15]" style={{ maxWidth: '50vw' }} dangerouslySetInnerHTML={{ __html: t('common.purchase_desc') }}></div>
            <div className="text-18 leading-37 pl-110 tracking-widest text-white font-[15]">↓</div>
            <a href="buy-manual" className="w-purchase-button text-white rounded-full py-2 px-4 ml-10 mt-10">
              {t('common.purchase_btn')}
            </a>
          </div>
          <div className="overview-item pl-[2vw]">
            <img src='https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_00.webp' className="overview-img" />
          </div>
        </div>

        {isNsfw && (
          <>
            <div className="flex items-center mt-15 module-container">
              <div className="banner-list">
                {banners.map((banner, index) => (
                  <BannerItem key={index} title={banner.title} desc={banner.desc} img={banner.img} index={index} />
                ))}
              </div>
            </div><div className="flex-1 items-center mt-15 mb-60 ml-[20vw] mr-[20vw] text-start">
              <div className="text-44 text-white font-bold mt-45 mb-10">
                {t('common.faq_title')}
              </div>
              {faqs.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div><div className="relative flex items-center mb-60 ending">
              <div className="overview-item pl-[2vw] relative">
                <img
                  src='https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_bottom.webp'
                  className="w-full h-auto"
                  alt="AI Lady" />
                <div className="absolute bottom-36 left-0 w-full text-center px-4">
                  <p className="text-36 text-white font-[800]">
                    {t('common.ending_try_desc')}
                  </p>
                </div>
                <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2 px-4">
                  <TryItBtn long={true} />
                </div>
              </div>
            </div>
          </>
        )}

        <Footer />
      </main>
    </>
  );
}

export default Home;
