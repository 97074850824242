import React, { useState, useRef, useEffect } from "react";
import "./home_h5.scss";
import { isAndroid } from "../../service/util";
import { isMobile } from "../../service/util";
import DownloadBtn from "./DownloadBtn";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const isNsfw = window.location.hostname.includes('nsfw');

const BannerItem: React.FC<{ title: string, desc: string, img: string }> = ({ title, desc, img }) => {
  return (
    <div className="banner-item flex flex-col items-center">
      <div className="banner-title font-bold mt-20 mb-10" dangerouslySetInnerHTML={{ __html: title }}>
      </div>
      <div className="banner-desc m-15" dangerouslySetInnerHTML={{ __html: desc }}>
      </div>
      <div className="banner-img m-10">
        <div>
          <img src={img} />
        </div>
      </div>
      <div className="m-10">
        <TryItBtn long={false} />
      </div>
    </div>
  );
};

const FAQItem: React.FC<{ question: string, answer: string }> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item py-2 mt-5 mb-5 ml-10">
      <div className="flex items-center justify-between cursor-pointer" onClick={toggleAnswer}>
        <span className="faq-question">{question}</span>
        <span className={`transition-transform duration-300 text-gray ${isOpen ? 'transform rotate-180' : ''}`}>
          ▼
        </span>
      </div>
      {isOpen && <div className="mt-26 faq-answer" dangerouslySetInnerHTML={{ __html: answer }}></div>}
    </div>
  );
};

const TryItBtn: React.FC<{ long: boolean }> = ({ long }) => {
  const { t } = useTranslation();
  const buttonStyle = {
    '--button-text': `"${t('common.try_btn')}"`,
    'width': long ? '320px' : '160px',
    'height': long ? '32px' : '30px',
  } as React.CSSProperties;
  return (
    <a href="https://p.aipeak.ai" className="try-button py-2 px-4 " style={buttonStyle}>
    </a>
  );
}

const H5: React.FC = () => {
  const { t } = useTranslation();
  const banners = [
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_01.webp',
      title: t('banner.title_1'),
      desc: t('banner.desc_1'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_02.webp',
      title: t('banner.title_2'),
      desc: t('banner.desc_2'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_03.webp',
      title: t('banner.title_3'),
      desc: t('banner.desc_3'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_04.webp',
      title: t('banner.title_4'),
      desc: t('banner.desc_4'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_05.webp',
      title: t('banner.title_5'),
      desc: t('banner.desc_5'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_06.webp',
      title: t('banner.title_6'),
      desc: t('banner.desc_6'),
    },
    {
      img: 'https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_07.webp',
      title: t('banner.title_7'),
      desc: t('banner.desc_7'),
    },
  ];
  const faqs = [
    { question: t('faq.q1'), answer: t('faq.a1') },
    { question: t('faq.q2'), answer: t('faq.a2') },
    { question: t('faq.q3'), answer: t('faq.a3') },
    { question: t('faq.q4'), answer: t('faq.a4') },
    { question: t('faq.q5'), answer: t('faq.a5') },
    { question: t('faq.q6'), answer: t('faq.a6') },
    { question: t('faq.q7'), answer: t('faq.a7') },
  ];
  // const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  // const toggleLanguageMenu = () => setIsLanguageMenuOpen(!isLanguageMenuOpen);

  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng);
  //   setIsLanguageMenuOpen(false);
  // };
  return (
    <>
      <div className="p-2 home-bg">
        <div className="flex justify-between items-center pt-10">
          <div className="home-title m-15">PicX</div>
          <LanguageSelector h5={true} />
        </div>
        <div className="flex flex-col items-center pt-10">
          <div className="home-subtitle font-bold m-5">{t('common.home_subtitle')}</div>
          <div className="home-desc mt-10 mb-30" dangerouslySetInnerHTML={{ __html: t('common.home_desc') }}></div>
          <div className="mt-30 mb-45 flex justify-between">
            {(!isMobile() || isAndroid()) && (
              <DownloadBtn type="android" h5={true} />
            )}
            {isNsfw && (
              <DownloadBtn type={"webapp"} h5={true} />
            )}
          </div>
          <div className="home-purchase-desc mt-10">
            {/* Purchase Credits With Better Price */}
            {t('common.purchase_desc')}
          </div>
          <div className="home-purchase-desc mt-7">
            <img className="mr-2" width="17" src={require("../../assets/images/home/down.webp")} />
          </div>
          <div className="mt-7 mb-35">
            <a href="buy-manual" className="purchase-button text-white rounded-full py-2 px-4 ml-4">
              {t('common.purchase_btn')}
            </a>
          </div>

          {isNsfw && (
            <>
              <div className="banner-list mb-26">
                {banners.map((banner, index) => (
                  <BannerItem key={index} title={banner.title} desc={banner.desc} img={banner.img} />
                ))}
              </div>

              <div className="faq-title font-bold mt-45 mb-10">
                {t('common.faq_title')}
              </div>
              <div className="faq-list mb-15">
                {faqs.map((faq, index) => (
                  <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
              </div>

              <div className="relative flex items-center mb-60 ending">
                <div className="overview-item relative">
                  <img
                    src='https://with-us.oss-us-west-1.aliyuncs.com/aipeak/home-web/en/web_en_bottom.webp'
                    className="w-full h-auto"
                    alt="AI Lady"
                  />
                  <div className="absolute bottom-24 left-0 w-full text-center">
                    <p className="text-20 text-white font-[800]">
                      {t('common.ending_try_desc')}
                    </p>
                  </div>
                  <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2">
                    <TryItBtn long={true} />
                  </div>
                </div>
              </div>
            </>
          )}
          <Footer />
          <div className="mb-350"></div>
        </div>
      </div>
    </>
  );
};

export default H5;
